import React from 'react';
import styled from 'styled-components';
import logo from 'static/logo.svg';
import LanguageSelect from './LanguageSelect';
import CurrencySelect from './CurrencySelect';

const Header = (): JSX.Element => {
  return (
    <Root>
      <Link href="https://parlaybay.com" key={'back-to-home'}>
        Visit ParlayBay.com
      </Link>
      <StandardLogoWrapper>
        <img
          width="10px"
          src={logo}
          alt="ParlayBay - The leading provider of sports betting games"
          onClick={() => {
            window.location.href = 'https://parlaybay.com';
          }}
        />{' '}
        <Demo>Demo</Demo>
      </StandardLogoWrapper>
      <SelectsWrapper>
        <LanguageSelect />
        <CurrencySelect disabled={false} />
      </SelectsWrapper>
    </Root>
  );
};

const Root = styled.div`
  gap: 8px;
  display: flex;
  padding: 12px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  margin: 0px auto;
  width: 90%;
  max-width: 1246px;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  @media only screen and (min-width: 900px) {
    gap: 20px;
    padding: 20px 0;
  }
`;

const Link = styled.a`
  color: #9381a6;
  font-size: 13px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const Demo = styled.div`
  cursor: initial;
  font-size: 22px;
  line-height: 38px;
  padding-left: 14px;
  border-left: solid white 1px;
  text-transform: uppercase;
  color: white;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 12px;
    padding-left: 6px;
  }
`;

const StandardLogoWrapper = styled.div`
  gap: 14px;
  display: flex;
  cursor: pointer;
  color: white;
  align-items: center;
  justify-content: center;

  img {
    width: 134px;
    height: 16px;
  }

  @media only screen and (max-width: 900px) {
    gap: 6px;
    img {
      width: 64px;
      height: 16px;
    }
  }
`;

const SelectsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export default Header;
