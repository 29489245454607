import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0 0 10px;
  }

  input {
    padding: 10px;
    margin: 10px 0;
  }

  .MuiSelect-filled {
    background: #320A5A;
    border-radius: 14px;
  }

  .MuiMenuItem-root {
    background: #532186;
    border-radius: 4px;
  }
`;

export default GlobalStyles;
