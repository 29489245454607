import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#06063a',
      main: '#14317e',
    },
    secondary: {
      main: '#ffb81c',
      light: '#fdde9b',
    }
  },
});

export default theme;