export type GitInfo = {
  version?: string;
  branch?: string;
  commit?: string;
};

const loadModule = async () => {
  try {
    const { version, branch, commit } = await import('gitinfo.json');
    return { version, branch, commit } as GitInfo;
  } catch (e) {
    return { version: '0', branch: '-', commit: '' };
  }
};
export const getGitInfo = loadModule();

const LogVersion = (): void => {
  setTimeout(
    () =>
      getGitInfo.then(
        (gitinfo) => {
          console.log(
            `%c %c Parlaybay demo version: ${gitinfo?.version}, branch: ${gitinfo?.branch}, commit: ${gitinfo?.commit} %c `,
            'background: #777;',
            'color: #444; background: #eee; ',
            'background: #111;',
          );
        },
        () => console.error('Failed to load version file'),
      ),
    1000,
  );
};

export default LogVersion;
