import * as React from 'react';
import Select from './Select';
import { countriesAndCurrenciesContext } from 'contexts/countriesAndCurrencies';

const CustomSelect = () => {
  const { country, setCountry, countries } = React.useContext(
    countriesAndCurrenciesContext,
  );

  return (
    <Select
      header="Language"
      value={country}
      onChange={(newValue) => setCountry(newValue)}
      options={countries.map(
        ({ language, countryCode, languageName }) => ({
          value: language,
          label: languageName,
          icon: countryCode
            ? `https://flagcdn.com/16x12/${countryCode}.png`
            : undefined,
        }),
      )}
    />
  );
};

export default CustomSelect;
