import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SIX_MONTHS_IN_MS } from 'utils/constants';

type Props = {
  onAccept?: () => void;
};

const CookieConsentPopup = ({ onAccept }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleAccept = () => {
    localStorage.setItem(
      'cookieConsentTimestamp',
      Date.now().toString(),
    );
    localStorage.setItem('cookieConsentAccepted', 'true');
    setShowPopup(false);
    onAccept?.();
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsentAccepted', 'false');
    setShowPopup(false);
  };

  const handleExpand = () => {
    setExpanded(true);
  };

  const showConsentPopup = () => {
    const consentTimestamp = localStorage.getItem(
      'cookieConsentTimestamp',
    );
    const consentAccepted = localStorage.getItem(
      'cookieConsentAccepted',
    );

    if (
      !consentTimestamp ||
      Date.now() - parseInt(consentTimestamp) > SIX_MONTHS_IN_MS
    ) {
      setShowPopup(consentAccepted !== 'false');
    }
  };

  useEffect(() => {
    showConsentPopup();
  }, []);

  if (!showPopup) return <></>;

  return (
    <>
      <PopupWrapper visible={showPopup}>
        <Title>We use cookies</Title>
        <Description>
          {expanded ? (
            <>
              We use cookies to improve your browsing experience, show
              you personalized content, analyze our website traffic,
              and understand where our visitors are coming from.
            </>
          ) : (
            <>
              We use cookies to improve your browsing...{' '}
              <Link onClick={handleExpand}>Read more</Link>
            </>
          )}
        </Description>
        <ButtonWrapper>
          <AcceptButton onClick={handleAccept}>Accept</AcceptButton>
          <DeclineButton onClick={handleDecline}>
            Decline
          </DeclineButton>
        </ButtonWrapper>
      </PopupWrapper>
    </>
  );
};

const PopupWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 9999;
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  padding: 16px;
  font-size: 14px;
  max-width: 415px;
  text-align: center;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Description = styled.div`
  margin-bottom: 8px;
`;

const Link = styled.a`
  cursor: pointer;
  color: #281461;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Button = styled.button`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  min-height: 25px;
  min-width: 100px;
  border-radius: 20px;
  text-align: center;
  background: #320a5a;
  border: none;
  color: white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    color: white;
    background: #532186;
    border: none;
    cursor: pointer;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 8px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 12px;
  box-sizing: border-box;
  min-height: 25px;
  min-width: 100px;
  border-radius: 20px;
  text-align: center;
  background: #320a5a;
  border: none;
  color: white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    color: white;
    background: #532186;
    border: none;
    cursor: pointer;
  }
`;

const DeclineButton = styled(Button)`
  margin-left: 8px;

  background: white;
  color: #320a5a;
  border: 1px solid #320a5a;

  &:hover {
    color: #532186;
    background: white;
    border: 1px solid #532186;
  }
`;

export default CookieConsentPopup;
