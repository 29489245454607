import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import CookieConsentPopup from 'components/CookieConsentPopup';
import { SIX_MONTHS_IN_MS } from 'utils/constants';
import { countriesAndCurrenciesContext } from 'contexts/countriesAndCurrencies';

const baseUrl = process.env.REACT_APP_IFRAME_URL;
export const getIFrameUrl = (
  allowTracking: boolean,
  currency: string,
  language?: string,
): string => {
  const prodUrl = baseUrl + '/?';
  const tracking = `&allowTracking=${allowTracking}`;
  const operator = '&operator=demo';
  const lang = language ? `&lang=${language}` : '';
  const provider = '&provider=sbg';
  const wallet = '&wallet=demo';
  const walletKey =
    '&walletKey=demo_' +
    Date.now() +
    Math.round(Math.random() * 10000000) +
    ':10000:' +
    currency +
    `&currency=${currency}`;
  return (
    prodUrl +
    tracking +
    wallet +
    walletKey +
    operator +
    lang +
    provider
  );
};

const Parlaybay: React.FC = (): JSX.Element => {
  const [allowTracking, setAllowTracking] = useState<boolean>(false);
  const { currency, country } = useContext(
    countriesAndCurrenciesContext,
  );

  useEffect(() => {
    const consentTimestamp = localStorage.getItem(
      'cookieConsentTimestamp',
    );
    const consentAccepted = localStorage.getItem(
      'cookieConsentAccepted',
    );

    if (
      consentTimestamp &&
      consentAccepted === 'true' &&
      Date.now() - parseInt(consentTimestamp) < SIX_MONTHS_IN_MS
    ) {
      setAllowTracking(true);
    }
  }, []);

  const url = getIFrameUrl(allowTracking, currency, country);

  return (
    <Root>
      <CookieConsentPopup onAccept={() => setAllowTracking(true)} />
      <IframeWrapper>
        <iframe
          id="game"
          src={url}
          title="ParlayBay"
          frameBorder="0"
          width="100%"
          allow="autoplay"
          allowFullScreen
        />
      </IframeWrapper>
    </Root>
  );
};

const Wrapper = styled.div`
  max-width: 1246px;
  width: 100%;
  margin: 0 auto;
`;

const Root = styled(Wrapper)`
  align-self: stretch;
  height: 100vh;

  @media only screen and (min-width: 600px) {
    height: 100%;
  }
`;

const IframeWrapper = styled.div`
  position: relative;
  height: 100%;

  iframe {
    display: block;
    position: relative;
    height: 100%;
  }

  @media only screen and (min-width: 600px) {
    iframe {
      height: calc(${window.innerHeight}px - 49px);
    }
  }

  @media only screen and (min-width: 900px) {
    iframe {
      height: calc(${window.innerHeight}px - 79px);
    }
  }
`;

export default Parlaybay;
