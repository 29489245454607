import React from 'react';
import { createTheme, ThemeProvider } from '@mui/system';
import styled from 'styled-components';
import GlobalStyles from 'services/styled/GlobalStyles';
import Parlaybay from 'pages/Demo';
import Header from './components/Header';
import LogVersion from 'utils/logversion';

LogVersion();

const App = (): JSX.Element => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppWrapper>
        <Header />
        <Parlaybay />
      </AppWrapper>
    </ThemeProvider>
  );
};

const AppWrapper = styled.div`
  background: linear-gradient(#06063a, #14317e);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default App;
