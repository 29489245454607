import * as React from 'react';
import {
  Select,
  selectClasses,
  SelectRootSlotProps,
} from '@mui/base/Select';
import { Option, optionClasses } from '@mui/base/Option';
import { Popper } from '@mui/base/Popper';
import styled from 'styled-components';

const CustomSelect = ({
  header,
  options,
  value,
  onChange,
  disabled,
}: {
  header: string;
  options: { label: string; value: any; icon?: string }[];
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
}) => {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
  };

  const handleChange = (event: any, value: any) => {
    onChange(value);
  };

  return (
    <Select
      // @ts-ignore
      slots={slots}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    >
      <StyledOption disabled>{header}</StyledOption>
      {options.map(({ label, value, icon }) => (
        <StyledOption value={value} key={value}>
          {icon ? (
            <img
              width="16"
              height="12"
              src={icon}
              style={{ marginRight: '5px' }}
            />
          ) : null}
          {label}
        </StyledOption>
      ))}
    </Select>
  );
};

const Button = React.forwardRef(function Button<
  TValue extends Record<string, unknown>,
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
    </button>
  );
});

const StyledButton = styled(Button)(
  ({ theme }) => `
  font-size: 12px;
  box-sizing: border-box;
  min-width: 100px;
  text-align: center;
  color: #fff;
  border-radius: 20px;
  background: rgb(50, 10, 90);
  cursor: pointer;
  border: none;
  position: relative;
  height: 25px;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: rgb(83, 33, 134);
  }

  &.${selectClasses.focusVisible} {
    background: rgb(83, 33, 134);
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  padding: 6px;
  margin: 5px 0;
  min-width: 120px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: rgb(50, 10, 90);
  border: 1px solid rgb(83, 33, 134);
  color: #fff;
  `,
);

export const StyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 5px;
  border-radius: 8px;
  cursor: default;
  margin-bottom: 1px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: rgb(83, 33, 134);
  }

  &.${optionClasses.highlighted} {
    background-color: rgb(83, 33, 134);
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: rgb(83, 33, 134);
  }

  &.${optionClasses.disabled} {
    color: #fff;
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: rgb(83, 33, 134);
  }
  `,
);

const StyledPopper = styled(Popper)`
  z-index: 1;
`;

export default CustomSelect;
