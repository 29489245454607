import React, { createContext, useState, useEffect } from 'react';

export type CurrencyType = {
  currency: string;
  countryCode: string;
};

export type CountryType = {
  language: string;
  countryCode: string;
  languageName: string;
};

export const countriesAndCurrenciesContext = createContext({
  countries: [],
  currencies: [],
  currency: '',
  setCurrency: () => {},
  country: '',
  setCountry: () => {},
} as {
  currency: string;
  setCurrency: (newCurrency: string) => void;
  country: string;
  setCountry: (newCurrency: string) => void;
  countries: CountryType[];
  currencies: CurrencyType[];
});

const CountriesAndCurrenciesProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
  const [country, setCountry] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  useEffect(() => {
    fetch('currencies.json').then(
      async (res: any) => {
        const data = (await res.json()) as CurrencyType[];
        setCurrencies(data);
        setCurrency(data[0].currency);
      },
      () => {
        console.error(
          'Could not get supportedCountriesAndCurrencies',
        );
      },
    );
  }, []);

  useEffect(() => {
    fetch('countries.json').then(
      async (res: any) => {
        const data = (await res.json()) as CountryType[];
        setCountries(data);
        setCountry(data[0].language);
      },
      () => {
        console.error(
          'Could not get supportedCountriesAndCurrencies',
        );
      },
    );
  }, []);

  return (
    <countriesAndCurrenciesContext.Provider
      value={{
        countries,
        currencies,
        currency,
        setCurrency: (newCurrency: string) =>
          setCurrency(newCurrency),
        country,
        setCountry: (newCurrency: string) => setCountry(newCurrency),
      }}
    >
      {children}
    </countriesAndCurrenciesContext.Provider>
  );
};

export default CountriesAndCurrenciesProvider;
