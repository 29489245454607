import * as React from 'react';
import Select from './Select';
import { countriesAndCurrenciesContext } from 'contexts/countriesAndCurrencies';

const CustomSelect = ({ disabled }: { disabled: boolean }) => {
  const { currency, setCurrency, currencies } = React.useContext(
    countriesAndCurrenciesContext,
  );

  return (
    <Select
      disabled={disabled}
      header="Currency"
      value={currency}
      onChange={(newValue) => setCurrency(newValue)}
      options={currencies.map(({ currency, countryCode }) => ({
        value: currency,
        label: currency.toUpperCase(),
        icon: `https://flagcdn.com/16x12/${countryCode}.png`,
      }))}
    />
  );
};

export default CustomSelect;
